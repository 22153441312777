import { storageToken } from "../shared/sessionData";

const inDev = process.env.NODE_ENV === "development";
const inTest = true;

const devPortalUrl = "http://localhost:3003";
const testPortalUrl = "https://develop.login.jalisco365.com.ar";
const prodPortalUrl = "https://login.jalisco365.com.ar";

const devPortalUrlBase = "https://localhost:44381/api/";
const testPortalUrlBase = "https://develop.login.api.jalisco365.com.ar/api/";
const prodPortalUrlBase = "https://login.api.jalisco365.com.ar/api/";

const devDashboardUrl = "http://localhost:3000";
const testDashboardUrl = "https://develop.dashboard.jalisco365.com.ar";
const prodDashboardUrl = "https://dashboard.jalisco365.com.ar";

const devVendedoresUrl = "http://localhost:3001";
const testVendedoresUrl = "https://develop.vendedores.jalisco365.com.ar";
const prodVendedoresUrl = "https://vendedores.jalisco365.com.ar";

const devAnalyticsUrl = "http://localhost:3002";
const testAnalyticsUrl = "https://develop.analytics.jalisco365.com.ar";
const prodAnalyticsUrl = "https://analytics.jalisco365.com.ar";

const devDessolUrl = "http://localhost:3002";
const testDessolUrl = "https://develop.dessol.jalisco365.com.ar";
const prodDessolUrl = "https://dessol.jalisco365.com.ar";

const devFarmaciasUrl = "http://localhost:3005"
const testFarmaciasUrl = "https://develop.farmacias.jalisco365.com.ar"
const prodFarmaciasUrl = "https://farmacias.jalisco365.com.ar"

const devIntegracionesUrl = "http://localhost:5173"
const testIntegracionesUrl = "https://develop.integraciones.jalisco365.com.ar"
const prodIntegracionesUrl = "https:/integraciones.jalisco365.com.ar"

export const urlPortal = () => {
  return window.location.replace(
    inDev ? devPortalUrl : inTest ? testPortalUrl : prodPortalUrl
  );
};

export const urlPortalLogout = () => {
  return window.location.replace(
    inDev
      ? `${devPortalUrl}/logout`
      : inTest
      ? `${testPortalUrl}/logout`
      : `${prodPortalUrl}/logout`
  );
};

export const urlVendedores = () => {
  return window.location.replace(
    inDev
      ? `${devVendedoresUrl}?${storageToken()}`
      : inTest
      ? `${testVendedoresUrl}?${storageToken()}`
      : `${prodVendedoresUrl}?${storageToken()}`
  );
};

export const urlDashboard = () => {
  return window.location.replace(
    inDev
      ? `${devDashboardUrl}?${storageToken()}`
      : inTest
      ? `${testDashboardUrl}?${storageToken()}`
      : `${prodDashboardUrl}?${storageToken()}`
  );
};

export const urlAnalytics = () => {
  return window.location.replace(
    inDev
      ? `${devAnalyticsUrl}?${storageToken()}`
      : inTest
      ? `${testAnalyticsUrl}?${storageToken()}`
      : `${prodAnalyticsUrl}?${storageToken()}`
  );
};

export const urlDessol = () => {
  return window.location.replace(
    inDev
      ? `${devDessolUrl}?${storageToken()}`
      : inTest
      ? `${testDessolUrl}?${storageToken()}`
      : `${prodDessolUrl}?${storageToken()}`
  );
};

export const baseUrlPortal = () => {
  return inDev
    ? devPortalUrlBase
    : inTest
    ? testPortalUrlBase
    : prodPortalUrlBase;
};

export const urlPortalCambiarPassword = () => {
  var urlPortal = inDev ? devPortalUrl : inTest ? testPortalUrl : prodPortalUrl;
  return `${urlPortal}/cambiarPassword`;
}

export const urlPortalFarmacias = () => {
  const url = inDev ? `${devFarmaciasUrl}` : inTest ? `${testFarmaciasUrl}` : `${prodFarmaciasUrl}`;
  return window.location.replace(`${url}?${storageToken()}`);
}

export const urlIntegraciones = () => {
  const url = inDev ? `${devIntegracionesUrl}` : inTest ? `${testIntegracionesUrl}` : `${prodIntegracionesUrl}`;
  return window.location.replace(`${url}?${storageToken()}`);
}